document.addEventListener('DOMContentLoaded', () => {
  const ageGate = document.querySelector('.age-gate');
  const yes = document.querySelector('.age-gate-buttons #yes');
  const no = document.querySelector('.age-gate-buttons #no');

  if (ageVerified !== 'TRUE') {
    ageGate.classList.add('show');
  }

  function setCookie() {
    Cookies.set('fiveSprings_verified', 'TRUE', { expires: 1 });
    ageGate.classList.remove('show');
  }

  function removeCookie() {
    if (Cookies.get('fiveSprings_verified')) {
      Cookies.remove('fiveSprings_verified');
      ageGate.classList.add('show');
    }
  }

  yes.addEventListener('click', setCookie);

  no.addEventListener('click', removeCookie);

  const drawer = document.querySelector('.products-drawer');
  const productsLink = document.querySelector('#products');
  const navContainer = document.querySelector('.nav-outer-wrapper > .nav');
  const navMenu = document.querySelector('.nav-menu');
  const nav = document.querySelector('nav.nav-container');
  const navContainerOuter = document.querySelector('.nav-container-outer');
  const productCards = document.querySelectorAll('.nav-product-link');

  const openArea = [
    drawer,
    productsLink,
    navContainer,
    navMenu,
    nav,
    navContainerOuter,
  ];

  let viewportWidth = window.innerWidth;

  function initializeNav() {
    if (viewportWidth > 991) {
      productsLink.addEventListener('mouseover', open);
      productsLink.addEventListener('keydown', handleKeydown);
    } else {
      productsLink.removeEventListener('mouseover', open);
      productsLink.removeEventListener('keydown', handleKeydown);
    }
  }

  function trackMouse(e) {
    if (
      !openArea.includes(e.target) &&
      !openArea.includes(e.target.closest('.products-drawer'))
    ) {
      close();
    }
  }

  window.addEventListener('resize', (e) => {
    viewportWidth = window.innerWidth;
    initializeNav();
  });

  function open() {
    window.addEventListener('mousemove', trackMouse);
    drawer.classList.add('open');
    productsLink.classList.add('hover');
  }

  function close() {
    window.removeEventListener('mousemove', trackMouse);
    drawer.classList.remove('open');
    productsLink.classList.remove('hover');
  }

  function focusOpen() {
    drawer.classList.add('open');
    productsLink.classList.add('hover');
  }

  function focusClose() {
    if (!document.activeElement.closest('.nav-product')) {
      drawer.classList.remove('open');
      productsLink.classList.remove('hover');
    }
  }

  function handleKeydown(e) {
    if (e.key === 'Enter') {
      focusOpen();
    }
  }

  productCards.forEach((card) => {
    card.addEventListener('focus', focusOpen);
    card.addEventListener('blur', focusClose);
  });

  initializeNav();
});
